<template>
  <v-container fluid class="px-lg-5">
    <app-header :title="title" :breadcrumbs="breadcrumbs" />
    <goals-table
      :tableName="goalTables.MANAGER_PENDING_REQUESTS"
      :goals="goals"
      :loading="loading"
      @updateOptions="updateOptions"
      @manageGoalRequest="manageGoalRequest">
    </goals-table>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import goalsTable from 'components/goals/Table.vue';
import {goalTables, goalStatus} from 'constants';

export default {
  name: 'managerGoals',
  components: {
    goalsTable
  },
  data() {
    return {
      loading: true,
      goalTables,
      title: {
        text: this.$t('layout.mainMenu.goalRequests'),
        icon: 'mdi-calendar-question'
      },
      breadcrumbs: [
        {
          text: this.$t('layout.mainMenu.home'),
          to: {name: 'Home'}
        },
        {
          text: this.$t('layout.mainMenu.requests'),
          to: {name: 'GoalRequests'},
          exact: true
        },
        {
          text: this.$t('layout.mainMenu.goals'),
          to: {name: 'GoalRequests'},
          exact: true
        }
      ],
      tab: null
    };
  },
  computed: mapGetters(['goals', 'currentUser', 'isAdmin']),
  methods: {
    ...mapActions(['manageGoal', 'getGoals']),
    async updateOptions(options) {
      this.loading = true;
      this.getGoals({
        ...options,
        filterBy: {
          ...options.filterBy,
          status: goalStatus.PENDING,
          manager_id: this.isAdmin ? '' : this.currentUser.id
        }
      }).then(() => {
        this.loading = false;
      });
    },
    manageGoalRequest(data) {
      this.loading = true;
      this.manageGoal(data).then((responseStatus) => {
        if (responseStatus === 200) {
          this.loading = false;
        }
      });
    }
  }
};
</script>
